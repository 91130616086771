const articleContent = document.querySelector('.article-content.compact');

if (articleContent) {
  const contentElement = articleContent.querySelector('.content-element');
  const contentElementHidden = articleContent.querySelector('.content-element-hidden');
  const showMoreBtn = articleContent.querySelector('#show-more');
 /* const lessHeight = '765px';*/

  if (showMoreBtn) {
    /*contentElement.style.maxHeight = lessHeight;*/

    showMoreBtn.addEventListener('click', () => {
      const { scrollHeight } = contentElement;


      contentElementHidden.classList.toggle('sr-only');

      if (!contentElementHidden.classList.contains('sr-only')) {
        showMoreBtn.textContent = 'Read less';
      } else {
        showMoreBtn.textContent = 'READ FULL ARTICLE';
      }
    });
  }
}
