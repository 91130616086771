import './utils/vh.utils.js';
import './utils/animate-on-view.utils';

import './libs/countUp.lib.js';

import './components/header.component.js';
import './components/animate-in-view.component.js';
import './components/article.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/sliders.component.js';
import './components/gsap.component.js';
