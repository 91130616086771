import Swiper, { Pagination, Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils';

const sliderBlock = document.querySelector('.slider-block__slider');
if (sliderBlock) {
  const sliderBlockSwiper = new Swiper(sliderBlock, {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    pagination: {
      type: 'bullets',
      el: '.slider-block__slider-bullets',
      clickable: true,
    },
    breakpoints: {
      551: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 1,
      },
    },
    modules: [Pagination, Autoplay],
  });

  swiperObserver(sliderBlockSwiper);
}

const goToHubSlider = document.querySelector(
  '.go-to-connect-hub__card-list-slider'
);

if (goToHubSlider) {
  const goToHubSwiper = new Swiper(goToHubSlider, {
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    pagination: {
      type: 'bullets',
      el: '.go-to-connect-hub__card-list-slider-bullets',
      clickable: true,
    },
    modules: [Autoplay, Pagination],
  });

  swiperObserver(goToHubSwiper);
}

const latestArticlesSlider = document.querySelector('.latest-articles-slider');

if (latestArticlesSlider) {
  const latestArticlesSwiper = new Swiper(latestArticlesSlider, {
    slidesPerView: 1,
    spaceBetween: 20,
    // loop: true,
    rewind: true,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.latest-articles-slider__btn.prev',
      nextEl: '.latest-articles-slider__btn.next',
    },
    breakpoints: {
      501: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      },
    },
    modules: [Autoplay, Navigation],
  });

  swiperObserver(latestArticlesSwiper);
}
