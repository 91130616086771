import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({
  ignoreMobileResize: true,
  syncInterval: 999999999,
  normalizeScroll: {
    target: window,
    type: 'wheel,touch',
    debounce: true,
  },
});

const scrollTextWrap = document.querySelector('.scroll-text');
if (scrollTextWrap) {
  const textBlocks = gsap.utils.toArray('.scroll-text__text');

  textBlocks.forEach((item) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: item,
        start: 'top-=200px center',
        end: 'center center',
        scrub: 0.75,
        once: true,
        // pin: true,
        // pinSpacing: false,
        // markers: true,
      },
    });

    tl.from(item, {
      xPercent: 100,
    }).to(item, {
      xPercent: 0,
    });
  });
}
